import type { AutocompleteResponse } from "./AutocompleteResponse";
import type { LanguageCode } from "src/utils/language";

export const AutocompleteApi = {
  async search(
    query: string,
    languageCode: LanguageCode,
    options?: { publicApiService?: boolean },
  ): Promise<AutocompleteResponse> {
    let requestURL = encodeURI(
      `https://www.rome2rio.com/api/1.6/autocomplete?key=oK8vkE5x&query=${query}&languageCode=${languageCode}`,
    );
    if (options?.publicApiService) {
      requestURL += "&publicApiService=true";
    }
    const response = await fetch(requestURL);
    if (!response.ok) {
      throw new Error(
        `Error retrieving autocomplete response: ${response.status} ${response.statusText}`,
      );
    }
    const bodyObject: AutocompleteResponse = await response.json();
    return bodyObject;
  },
};
