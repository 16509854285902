import type { Input, Place } from "./search-form.marko";
import { sendEvent } from "src/analytics/sendEvent";
import { setPopunderUserPreference } from "src/popunder/Popunder";
import type { Meta } from "@marko/run";

export interface State {
  originPlace: Place;
  destinationPlace: Place;
  hasBeenModified: boolean;
  eventCategory: string;
}

export default class extends Marko.Component<Input, State> {
  onCreate(input: Input, out: Marko.Out) {
    this.state = {
      originPlace: input.originPlace,
      destinationPlace: input.destinationPlace,
      hasBeenModified: false,
      eventCategory: (out.global.meta as Meta).analytics.page,
    };
  }
  handleOriginChanged(place: Place) {
    this.state.originPlace = place;
    this.state.hasBeenModified = true;
  }

  handleDestinationChanged(place: Place) {
    this.state.destinationPlace = place;
    this.state.hasBeenModified = true;
  }

  handlePartnerSearchToggled(_: Event, element: HTMLInputElement) {
    setPopunderUserPreference(element.checked);
  }

  handleSwitchClicked() {
    sendEvent({
      category: this.state.eventCategory,
      action: "ToFromSwapped",
    });
    const origin = this.state.originPlace;
    this.state.originPlace = this.state.destinationPlace;
    this.state.destinationPlace = origin;
    this.state.hasBeenModified = true;
  }
}
