import { messages } from "../../messages";
import { buildExplorePathname } from "src/url/buildExplorePathname";
export interface Input {
  originCanonicalName: string;
  destinationCanonicalName: string;
  eventCategory: string;
  hasBeenModified: boolean;
  class?: Marko.HTMLAttributes["class"];
  icon: Marko.AttrTag<{ renderBody: Marko.Body }>;
  textClass?: Marko.HTMLAttributes["class"];
  label?: string;
}
<exit-link
  class=[
    "flex h-11 items-center justify-center rounded-lg bg-rome2rio-pink transition-opacity hover:opacity-90",
    input.class,
  ]
  href=buildExplorePathname(
    $global.languageCode,
    input.originCanonicalName,
    input.destinationCanonicalName,
  )
  method="Popunder"
  view=input.eventCategory
  trigger="TransportSearch"
  originCanonicalName=input.originCanonicalName
  destinationCanonicalName=input.destinationCanonicalName
  data-log="on-click"
  data-category=input.eventCategory
  data-action="Transport:Search"
  data-label=(input.hasBeenModified ? "Modified" : "NotModified") +
    (input.label ? `:${input.label}` : "")
  role="button"
  tabindex="0"
>
  <span class="relative"><${input.icon} /></span>
  <span
    class=[
      "text-base font-medium tracking-[.05em] text-white ml-2",
      input.textClass,
    ]
  >
    <formatted-message message=messages.searchButtonLabel no-update />
  </span>
</exit-link>
