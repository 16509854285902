export interface Input {
  class?: string;
}
<svg
  viewBox="0 0 32 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  class=input.class
>
  <path
    d="M10.667 0L17.75 7.083H12.417L12.417 19.583H8.91699L8.91699 7.083H3.58299L10.667 0ZM14.25 24.917H19.583V12.417H23.083V24.917H28.417L21.333 32L14.25 24.917Z"
    fill="currentColor"
  />
</svg>
