import { messages } from "./messages";
export interface Place {
  shortName: string;
  longName: string;
  canonicalName: string;
}
export interface Input {
  kind: "origin" | "destination";
  class?: string;
  listClass?: string;
  place: Place;
  "on-place-changed": (place: Place) => void;
}
<!--
  We need to use a global Map to track the latest query ids because let's
  are captured by the autocomplete inline function. In React this was solved
  by using a Ref. Ref's don't exist in Marko, so this is the next best thing.
-->
<div class="relative">
  <label
    id:scoped="place-autocomplete-label"
    for=`place-autocomplete-${input.kind}`
    class="mb-2 text-xs font-medium uppercase tracking-widest text-grey-suit"
  >
    <if(input.kind === "origin")>
      <formatted-message message=messages.originLabel no-update />
    </if>
    <else>
      <formatted-message message=messages.destinationLabel no-update />
    </else>
  </label>
  $ {
    const resultsExpanded: boolean = !!(
      state.autocompleteResults.length && state.isAutocompleteListOpen
    );
  }
  <input
    key="input"
    id=`place-autocomplete-${input.kind}`
    class=[
      "peer h-12 w-full select-all rounded-lg border border-border-grey bg-n-20 px-4 text-lg text-[#111] focus:border-black focus:ring-black",
      input.class,
    ]
    type="text"
    value=component.getInputValueWithHint()
    role="combobox"
    autocomplete="off"
    autocapitalize="off"
    spellcheck="false"
    aria-describedby:scoped="place-autocomplete-label"
    aria-owns:scoped="place-autocomplete-listbox"
    aria-expanded=(resultsExpanded ? "true" : "false")
    aria-autocomplete="list"
    on-input("onSearchBoxInput")
    on-focus("onSearchBoxFocused")
    on-focusout("onSearchBoxFocusOut")
    on-keydown("onInputKeyDown")
  />
  $ {
    const activeDescendant = !!state.highlightedResult
      ? `${component.elId()}-place-${state.highlightedResult}`
      : undefined;
  }
  <ul
    id:scoped="place-autocomplete-listbox"
    role="listbox"
    class="absolute z-10 hidden w-full divide-y divide-solid rounded bg-white shadow peer-aria-expanded:block"
    aria-activedescendant=activeDescendant
  >
    <for|place, index| of=state.autocompleteResults>
      $ const isResultHighlighted = index + 1 === state.highlightedResult;
      <li
        id:scoped=`place-${index + 1}`
        role="option"
        key=place.canonicalName
        tabindex=-1
        class="flex min-h-12 cursor-pointer items-center py-2 px-4 hover:bg-gray-100 aria-selected:bg-gray-100 md:px-8"
        aria-selected=(isResultHighlighted ? "true" : "false")
        on-mousedown("onAutocompleteSelected", place, "Click", index)
      >
        ${place.longName}
      </li>
    </for>
  </ul>
</div>
