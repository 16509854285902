import { defineMessages } from "@formatjs/intl";

export const messages = defineMessages({
  switchButtonLabel: {
    id: "switch-button.label",
    defaultMessage: "Swap from and to",
    description:
      "The label for a button that switches the origin and destination.",
  },
});
