import { messages } from "./messages";
export interface Input {
  class?: string;
  "on-click": VoidFunction;
}
class {
  declare state: {
    pressed: boolean;
  };
  onCreate() {
    this.state = {
      pressed: false,
    };
  }
  handleClick() {
    this.emit("click");
    this.state.pressed = !this.state.pressed;
  }
}
<button
  class=[
    "group h-12 w-12 rounded-full bg-white p-3 text-black shadow-md hover:text-rome2rio-pink",
    input.class,
  ]
  on-click("handleClick")
  aria-label:no-update=$global.intl?.formatMessage(messages.switchButtonLabel)
  aria-pressed=(state.pressed ? "true" : "false")
  tabindex=-1 /* To match legacy, once we've rolled out we should remove this. */
>
  <switch class="transition-transform group-aria-pressed:rotate-180 " />
</button>
