export interface Place {
  shortName: string;
  longName: string;
  canonicalName: string;
}
export interface Input {
  originPlace: Place;
  destinationPlace: Place;
  noSearchTitle?: boolean;
  class?: Marko.HTMLAttributes["class"];
}

<div class=[input.class, "flex flex-col"]>
  <div class="relative flex flex-col md:gap-y-6">
    <place-autocomplete
      kind="origin"
      class="h-12 w-full text-[#111]"
      place=state.originPlace
      on-place-changed("handleOriginChanged")
    />
    <place-autocomplete
      kind="destination"
      class="h-12 w-full text-[#111]"
      place=state.destinationPlace
      on-place-changed("handleDestinationChanged")
    />
    <switch-button
      class="absolute right-3 top-1/2 -translate-y-1/4"
      on-click("handleSwitchClicked")
    />
  </div>

  <search-button
    originCanonicalName=(state.originPlace.canonicalName)
    destinationCanonicalName=(state.destinationPlace.canonicalName)
    eventCategory=state.eventCategory
    hasBeenModified=state.hasBeenModified
    class="mt-6"
  >
    <@icon><search-logo class="h-[18px] w-[18px] fill-white" /></@icon>
  </search-button>
</div>
