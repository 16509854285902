import { selectPopunderEligibility } from "src/popunder/Popunder";
import { messages } from "../../messages";
import { selectHotelName } from "./hotelNameSelector";
import { setPopunderUserPreference } from "src/popunder/Popunder";

$ {
  const popunderEligibility = selectPopunderEligibility(
    $global.request,
    $global.backendFeatureConfig.PopunderRateLimit,
  );
}
<!-- Checkbox that controls whether we perform a leave-behind to one of our partners like Booking.com or Expedia.  -->

<input
  type="checkbox"
  id:scoped="search-partners"
  name:scoped="search-partners"
  on-click((element) => {
    setPopunderUserPreference((<HTMLInputElement>element.target).checked)
  })
  checked=(popunderEligibility === "Enabled")
  class="cursor-pointer rounded text-white focus:border-black focus:ring-black"
  tabindex=-1 /* To match legacy, once we've rolled out we should remove this. */
/>
<label
  for:scoped="search-partners"
  class="cursor-pointer pl-2 text-sm text-white"
>
  <if($global.popunderProvider !== undefined)>
    <formatted-message
      message=messages.accomodationLabel
      values={ provider: selectHotelName($global.popunderProvider) }
      no-update
    />
  </if>
  <else>
    <formatted-message
      message=messages.accommodationLabelNoProvider
      no-update
    />
  </else>
</label>
<!-- Log the default status of the popunder checkbox. -->
<log-analytics-event-on-mount
  category="Hotel"
  action="LandingPopunder:Default"
  label=(
    popunderEligibility === "Enabled"
      ? "Checked"
      : `Unchecked:${popunderEligibility}`
  )
/>
