export function selectHotelName(value: string): string {
  switch (value) {
    case "BookingCom":
      return "Booking.com";
    case "HotelsCom":
      return "Hotels.com";
    default:
      return value;
  }
}
