import { defineMessages } from "@formatjs/intl";

export const messages = defineMessages({
  originLabel: {
    id: "label.origin",
    defaultMessage: "Travel from",
    description: "The label for the origin input in the search form.",
  },
  destinationLabel: {
    id: "label.destination",
    defaultMessage: "To",
    description: "The label for the destination input in the search form.",
  },
});
