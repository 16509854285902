import { defineMessages } from "@formatjs/intl";

export const messages = defineMessages({
  searchButtonLabel: {
    id: "search-header.search-form.search-button-label",
    defaultMessage: "See all options",
    description:
      "The text for a search button in the search form, prompting a user to search for trips between their origin and destination.",
  },
  searchFormTitle: {
    id: "search-header.search-form.title",
    defaultMessage: "Find Transport to {destination}",
    description:
      "The title element above the search form containing the origin and destination.",
  },
  accomodationLabel: {
    id: "search-header.search-form.accomodation-label",
    defaultMessage: "Search accommodation with {provider}",
    description: "The label for a checkbox that enables accommodation search.",
  },
  accommodationLabelNoProvider: {
    id: "search-header.search-form.accommodation-label-no-provider",
    defaultMessage: "Search accommodation with our partners",
    description:
      "The label for a checkbox that enables accommodation search when the popunder is unknown.",
  },
});
